.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
  }
  
  .contact-item i {
    margin-right: 10px;
    font-size: 24px;
    text-align: center;
  }