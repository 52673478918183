.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px;
    background-color: #8b4b3e; /* Adjust the color to match your design */
    color: white;
  }
  
  .icon {
    font-size: 100px; /* Adjust the size to match your design */
    margin-bottom: 20px;
  }
  
  .aboutTitle {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .aboutDescription {
    font-size: 18px;
    max-width: 800px;
  }
  
  @media (max-width: 768px) {
    .aboutContainer {
      padding: 30px;
    }
  
    .aboutTitle {
      font-size: 24px;
    }
  
    .aboutDescription {
      font-size: 16px;
    }
  }
  