.gallery-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .gallery-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  
  .gallery img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    .gallery {
      grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .gallery {
      grid-template-columns: 1fr; /* One column on even smaller screens */
    }
  }