.booking-form-container {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 12px;
    background-color: #8b4b3e;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .booking-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #f0e0d6;
    font-weight: bold;
    font-size: 14px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .bookingButton {
    display: block;
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 6px;
    background-color: #2e8b57;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .bookingButton:hover {
    background-color: #246c46;
  }
  
  @media (min-width: 481px) {
    .booking-form-container {
      padding: 30px;
    }
  
    .booking-form-container h2 {
      font-size: 28px;
      margin-bottom: 25px;
    }
  
    .form-group {
      margin-bottom: 20px;
    }
  
    .form-group label {
      font-size: 16px;
      margin-bottom: 8px;
    }
  
    .form-group input,
    .form-group textarea {
      font-size: 16px;
      padding: 12px;
    }
  
    button {
      font-size: 18px;
    }
  }