.home {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/Home/Home1.jpg");
  background-size: cover;
  background-position: center;
  padding-left: 100px;
  padding-right: 100px;
}

.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(5px); /* Add slight blurriness */
  z-index: -1;
}

.mainTextContainer {
  padding-bottom: 100px;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Improve contrast of text */
}

.mainTextContainer h1 {
  font-size: 90px;
  color: #ffffff;
  margin-bottom: 0;
  padding-bottom: 0;
}

.mainTextContainer p {
  font-size: 20px;
  color: #ffcc00; /* Adjust color for better contrast */
  padding-bottom: 100px;
  margin-top: 0;
  padding-top: 0;
  font-weight: bold;
}

.animation {
  width: 450px;
  height: 450px;
}

@media (max-width: 768px) {
  .home {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mainTextContainer h1 {
    font-size: 60px;
  }

  .mainTextContainer p {
    font-size: 16px;
    padding-bottom: 50px;
  }

  .animation {
    width: 300px;
    height: 300px;
  }
}