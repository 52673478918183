.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.9); /* Updated background color */
}

.toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  z-index: 1100;
}

.nav-items {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: left 0.3s;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-items.open {
  left: 0;
}

.nav-items ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.nav-items li {
  margin: 10px 0;
}

.nav-items button {
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
}

.nav-items button:hover {
  color: #ffcc00;
}

@media (min-width: 768px) {
  .nav-items {
    position: static;
    width: auto;
    height: auto;
    background-color: rgba(0, 0, 0, 0.9); /* Updated background color */
    flex-direction: row;
    justify-content: flex-end;
  }

  .nav-items ul {
    display: flex;
    flex-direction: row;
  }

  .nav-items li {
    margin: 0 20px;
  }

  .toggle {
    display: none;
  }
}
