.App {
  /* background: #E4FDE1; */
}

.header {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10% 0 10%;
}

.header h1 {
  padding: 0;
  margin-top: 0;
  color: #456990;
  text-align: left;
  display: flex;
}

.header img {
  height: 400px;
  width: 400px;
}

.campingImage {
  height: 200;
  width: 200;
}

.headerText {

}

.headerText h1{
  text-align: left;
  margin-right: 5%;
  font-size: 50px;
}

.button {
  display: flex;
  width: 200px;
  background-color: #5C75F4;
  justify-content: center;
  border-radius: 25px;
  color: #F2F2F2;
}

.button p {
  font-size: medium;
}

.aboutSection {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aboutSection h1 {
  margin: 0 20% 0 20%;
  text-align: center;
  color: #456990;
}

.aboutSection img {
  height: 150px;
  width: 150px;
  margin: 5% 0 0 5%;
}

.ourGallery {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ourGallery h1 {
  margin-top: 10%;
  color: #456990;
}

.images {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10%;
  flex-wrap: wrap;
}

.images img {
  height: 200px;
  margin: 1%;

}

.PandB {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PandB h1,h2 {
  color: #456990;
}

.card {
  background-color: #ddcbcf;
  padding: 4%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  margin: 5%;
}

.bookingPro {
  background-color: #ddcbcf;
  padding: 4%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  margin: 5%;
}

.card img {
  align-self: center;
}

.bookingPro img {
  align-self: center;
}

.bankingDetails {
  background-color: #ddcbcf;
  padding: 4%;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  margin: 5%;
}

.bankingDetails h3 {
  color: #456990;
}

@media screen and (max-width: 500px) {
    .header {
      height: 100vh;
      display: flex;
      flex-direction: column;
      margin: 0 10% 0 10%;
      justify-content: space-evenly;
    }

    .header h1 {
      color: #456990;
      text-align: center;
      display: flex;
    }

    .headerText {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .headerText h1{
      text-align: center;
      margin-right: 0;
      font-size: 35px;
    }

    .header img {
      height: 250px;
    }

    .button p {
      font-size: small;
    }

    .ourGallery {
      display: flex;
      flex-direction: column;
    }

    .images {
      display: flex;
      flex-direction: column;
    }

    .aboutSection h1 {
      margin: 0 5% 0 5%;
      text-align: center;
      color: #456990;
      font-size: 29px;
    }

    .aboutSection img {
      margin: 10% 0 10% 0;
    }

    .images img {
      height: 200px;
      margin: 3%;
    }
}